.project-item-wrapper {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    height: 400px;
    align-items: center;
    .project-name {
        flex-wrap: wrap;
        max-width: 10rem;
    }
}

.project-item-image {
    width: 20rem;
    aspect-ratio: 1;
    object-fit: cover;
    cursor: pointer;
}
