@import '../../utils/colors/colors.scss';

.home-wrapper-about {
    overflow-x: hidden;
    max-width: 100%;
}
.intro-background-about {
    display: flex;
    height: 35rem;
    width: 100%;
    object-fit: cover;
}
.intro-wrapper-about {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $main;
    padding: 50px 100px 50px 100px;
}

.intro-title-about {
    font-size: 5rem;
}

.social-title-about {
    font-size: 4rem;
    margin: 0 !important;
    padding: 0 !important;
}

.intro-desc-about {
    display: flex;
    flex-direction: column;
    padding: 10px 0 0px 10px;
    font-size: 20px;
    align-self: center;
}

.intro-image-about {
    display: flex;
    justify-content: flex-end;
}

.intro-image-logo-about {
    display: flex;
    width: 35rem;
    height: 25rem;
    // margin: 2rem;
    object-fit: cover;
    opacity: 0.7;
}

h1 {
    padding: 0 10px 0 0;
    font-family: 'Share Tech Mono', monospace;
    color: $white;
}

h2 {
    padding: 0 10px 0 0;
    font-family: 'Share Tech Mono', monospace;
    color: $white;
}

h3 {
    padding: 0 10px 0 0;
    font-family: 'Share Tech Mono', monospace;
    color: $white;
}

button {
    :hover {
        color: $secondary;
    }
}

.certificate-wrapper-about {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: $main;
    align-items: flex-start;
    text-align: center;
    justify-content: center;
    padding: 50px 100px 50px 100px;
}

.skills-wrapper-about {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: $secondary;
    align-items: flex-start;
    text-align: center;
    justify-content: center;
    padding: 50px 100px 50px 100px;
}
.project-show-wrapper-about {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item-list-about {
    display: flex;
    flex: 1;
    width: 60rem;
    white-space: nowrap;
    flex-direction: row;
    position: relative;
    overflow-x: scroll;
    white-space: nowrap;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.project-button-about {
    display: flex;
    background-color: $green;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    height: 50px;
    width: 250px;
    border: 0;
    box-shadow: 3px 3px 3px $secondary;
    margin: 20px;
    cursor: pointer;
}

.find-me-wrapper-about {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: $secondary;
    align-items: center;
    text-align: center;
    padding: 50px 100px 50px 100px;
}

.social-connect-about {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}

.social-button-about {
    display: flex;
    background-color: $green;
    border-radius: 50rem;
    height: 8rem;
    width: 8rem;
    border: none;
    margin: 20px 20px 50px 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.social-logo-about {
    width: 8rem;
    height: 8rem;
}

.all-rights-wrapper-about {
    display: flex;
    justify-content: flex-end;
    background-color: $green;
    padding-right: 50px;
}

.skill-progress-layout {
    display: flex;
    flex-wrap: wrap;
}

.skill-item {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 10rem;
}

.skill-line {
    display: none;
}

.skill-circle {
    display: flex;
}

.certificate-list-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: left;
}

.certificate-item {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 480px) {
    .intro-background-about {
        height: 25rem;
    }
    .intro-image-about {
        flex: 1;
        justify-content: center;
        opacity: 0.2;
    }
    .intro-desc-about {
        flex: 1;
        position: absolute;
        text-align: left;
    }
    .intro-image-logo-about {
        margin: 0rem;
    }
    .intro-wrapper-about {
        padding: 50px 30px 50px 30px;
    }
    .certificate-wrapper-about {
        padding: 50px 30px 50px 30px;
    }
    .skills-wrapper-about {
        padding: 50px 30px 50px 30px;
    }
    .project-show-wrapper-about {
        flex: 1;
        position: absolute;
        z-index: 10;
    }
    .item-list-about {
        width: 30rem;
        margin-left: 10rem;
    }
    .contact-wrapper-about {
        padding: 50px 30px 50px 30px;
    }
    .contact-input-email-about {
        width: 15rem;
    }
    .contact-input-message-about {
        width: 15rem;
    }
    .all-rights-wrapper-about {
        padding-right: 0 !important;
    }
    .find-me-wrapper-about {
        padding: 50px 30px 50px 30px;
    }
    .social-connect-about {
        padding: 0 10px 0 10px;
    }
    .social-title-about {
        font-size: 3rem;
        margin: 0 !important;
    }
    .social-button-about {
        height: 5rem;
        width: 5rem;
        margin: 10px;
    }
    .social-logo-about {
        height: 4rem;
        width: 4rem;
    }
    .skill-progress-layout {
        display: flex;
        flex: 1;
        width: 100%;
    }
    .skill-item {
        h3 {
            align-self:flex-start;
        }
    }
    .skill-line {
        display: flex;
        width: 200px;
    }
    .skill-circle {
        display: none;
    }
    .skill-progress-layout {
        flex: 1;
        flex-wrap: nowrap;
        flex-direction: column;
    }
    .certificate-list-wrapper {
        display: flex;
        flex-direction: column;
        text-align: left;
    }
    .certificate-item {
        display: flex;
        flex-direction: column;
    }
}

// ipads, tablets
@media screen and (min-width: 481px) and (max-width: 768px) {
    .intro-desc-about {
        position: absolute;
    }
    .intro-image-about {
        justify-content: center;
        opacity: 0.3;
    }
}
// small screens, laptops
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .intro-wrapper-about {
        padding: 50px 50px 50px 50px;
    }
    .intro-image-logo-about {
        width: 20rem;
    }
}
// large screens
@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .intro-wrapper-about {
        padding: 50px 50px 50px 50px;  
    }
    .intro-image-logo-about {
        width: 20rem;
    }
}
// extra large
@media screen and (min-width: 1201px) {
    .intro-wrapper-about {
        padding: 50px 50px 50px 50px;
    }
}
