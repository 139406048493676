@import '../../utils/colors/colors.scss';


.home-wrapper-project-item {
    overflow-x: hidden;
    max-width: 100%;
}
.intro-background-project-item {
    display: flex;
    height: 35rem;
    width: 100%;
    object-fit: cover;
}
.intro-wrapper-project-item {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    background-color: $main;
}

.intro-title-project-item {
    font-size: 3rem;
    margin-left: 2rem;
}

.social-title-project-item {
    font-size: 4rem;
    margin: 0 !important;
    padding: 0 !important;
}

.intro-desc-project-item {
    display: flex;
    flex: 3;
    flex-direction: column;
    padding: 20px 0 0 20px;
    font-size: 20px;
}

.intro-image-project-item {
    display: flex;
    flex: 2;
    justify-content: flex-end;
}

.intro-image-logo-project-item {
    display: flex;
    width: 35rem;
    height: 25rem;
    margin: 2rem;
    object-fit: cover;
    opacity: 0.6;
}

h1 {
    padding: 0 10px 0 0;
    font-family: 'Share Tech Mono', monospace;
    color: $white;
}

h2 {
    padding: 0 10px 0 0;
    font-family: 'Share Tech Mono', monospace;
    color: $white;
}

h3 {
    padding: 0 10px 0 0;
    font-family: 'Share Tech Mono', monospace;
    color: $white;
}

button {
    :hover {
        color: $secondary;
    }
}

.projects-wrapper-project-item {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: $secondary;
    align-items: flex-start;
    justify-content: space-around;
    padding: 50px 100px 50px 100px;
}

.project-item-wrapper-project-item {
    display: flex;
    flex: 1;
    text-align: left;
    align-items: flex-start;
    flex-direction: column;

    .project-item-description {
        display: flex;
        flex-direction: column;
        // padding: 20px;
    }

    .project-link-project-item {
        display: flex; 
        flex-direction: row;
        align-items: center;
        a {
            color: $green;
            font-size: 20px;
        }
    }

    .project-image {
        // width: 200px;
        width: 20rem;
        // aspect-ratio: 1;
        object-fit: cover;
        padding: 5px;
    }
}

.find-me-wrapper-project-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: $secondary;
    align-items: center;
    text-align: center;
    padding: 50px 100px 50px 100px;
}

.social-connect-project-item {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}

.social-button-project-item {
    display: flex;
    background-color: $green;
    border-radius: 50rem;
    height: 8rem;
    width: 8rem;
    border: none;
    margin: 20px 20px 50px 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.social-logo-project-item {
    width: 8rem;
    height: 8rem;
}

.all-rights-wrapper-project-item {
    display: flex;
    justify-content: flex-end;
    background-color: $green;
    padding-right: 50px;
}

@media screen and (max-width: 480px) {
    .intro-background-project-item {
        height: 25rem;
    }
    .intro-image-project-item {
        flex: 1;
        justify-content: center;
        opacity: 0.2;
    }
    .intro-wrapper-project-item {
        flex: 1;
        height: auto;
        z-index: 10;
    }
    .intro-desc-project-item {
        flex: 1;
        text-align: left;
    }
    .intro-image-logo-project-item {
        margin: 0rem;
    }
    .projects-wrapper-project-item {
        padding: 50px 30px 50px 30px;
    }
    .project-item-wrapper-project-item {
    
        .project-item-description {
            padding: 20px;
        }

        .project-image {
            // width: 200px;
            width: 20rem;
        }
    
    }
    .all-rights-wrapper-project-item {
        padding-right: 0 !important;
    }
    .find-me-wrapper-project-item {
        padding: 50px 30px 50px 30px;
    }
    .social-connect-project-item {
        padding: 0 10px 0 10px;
    }
    .social-title-project-item {
        font-size: 3rem;
        margin: 0 !important;
    }
    .social-button-project-item {
        height: 5rem;
        width: 5rem;
        margin: 10px;
    }
    .social-logo-project-item {
        height: 4rem;
        width: 4rem;
    }
}

// ipads, tablets
@media screen and (min-width: 481px) and (max-width: 768px) {
    .intro-desc {
        position: absolute;
    }
    .intro-image {
        justify-content: center;
        opacity: 0.3;
    }
    .project-show-wrapper {
        flex: 1;
        position: absolute;
        z-index: 10;
    }
    .about-show-wrapper {
        flex: 1;
        position: absolute;
        z-index: 10;
    }
}
// small screens, laptops
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .intro-wrapper {
        padding: 50px 50px 50px 50px;
    }
    .intro-image-logo {
        width: 20rem;
    }
    .project-item-wrapper-project-item {
        .project-image {
            width: 25rem;
        }
    }
}
// large screens
@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .project-item-wrapper-project-item {
        .project-image {
            width: 40rem;
            height: 30rem;
            // aspect-ratio: 1;
            background-color: $main;
            object-fit: contain;
            margin: 1rem;
        }
    }
}
// extra large
@media screen and (min-width: 1201px) {
    .project-item-wrapper-project-item {
        .project-image {
            width: 40rem;
            height: 30rem;
            // aspect-ratio: 1;
            object-fit: contain;
            background-color: $main;
            margin: 2rem;
        }
    }
}